
import { gpf } from "@/utils/global-functions";
import { defineComponent } from "vue";

export default defineComponent({
  setup(_, { emit }) {
    const submitCat = () => {
      emit("submit-cat");
    };

    return {
      gpf,
      submitCat,
    };
  },
});
